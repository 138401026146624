import Vue from "vue";
import VueRouter from "vue-router";

import {
  UserUtil
} from "@/utils/user";

// const routerPush = VueRouter.prototype.push;

// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return routerPush.call(this, location, onResolve, onReject);
//   return routerPush.call(this, location).catch((error) => error);
// }

Vue.use(VueRouter);

const routes = [{
    path: "/",
    redirect: "main",
  },
  {
    path: "/sign-in",
    name: "signin",
    component: () => import("../pages/login/index.vue"),
  },
  {
    path: "/main",
    name: "main",
    component: () => import("../pages/index.vue"),
    redirect: "/charge",
    children: [{
        path: "/home",
        name: "home",
        component: () => import("../pages/home/home.vue"),
      },
      {
        path: "/charge",
        name: "charge",
        component: () => import("../pages/charge/index.vue"),
      },
      {
        path: "/meals-fee",
        name: "meals-fee",
        component: () => import("../pages/meals-fee/index.vue"),
      },
      {
        path: "/fapiao",
        name: "fapiao",
        component: () => import("../pages/fapiao/index.vue"),
      },
      {
        path: "/reimbursements",
        name: "reimbursements",
        component: () => import("../pages/invoice/index.vue"),
      }
    ],
  },
  {
    path: "/receipts",
    name: "receipt",
    component: () => import("../pages/invoice/index.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// 挂载路由守卫
router.beforeEach((to, from, next) => {
  // let toPath = to.path;
  // let hasLogged = false;
  // try {
  //   let user = UserUtil.get();
  //   if (user.token && user.token != "") {
  //     hasLogged = true;
  //   }
  // } catch (e) {
  //   console.log(e);
  //   hasLogged = false;
  // }
  // // hasLogged = false
  // console.log(`has logged : ${hasLogged}, to : ${toPath}`);
  // if (!hasLogged && toPath != "/sign-in") {
  //   return next("/sign-in");
  // } else if (hasLogged && toPath == "/sign-in") {
  //   return next("/home");
  // }
  return next();
});

export default router;