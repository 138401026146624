// 加密解密
let Base64 = require("js-base64").Base64;
import Cookies from "js-cookie";

const Cookie_Authorization_Key = "Authorization";
const Cookie_User_Key = "user";
const Cookie_Role_Key = "role";
const Cookie_User_Config_Key = "user_config";
const Cookie_User_School_Key = "user_school_info"

export const StorageUtil = {
  GLOBAL_AREA: 'global_data_area',

  save(key, data) {
    window.localStorage.setItem(key, JSON.stringify(data));
  },
  get(key) {
    let data = null;
    try {
      let jsonData = window.localStorage.getItem(key);
      data = JSON.parse(jsonData);
    } catch (e) {}
    return data;
  },
  remove(key) {
    if (key) {
      window.localStorage.removeItem(key);
    } else {

      window.localStorage.clear()
    }
  },

  session: {
    save(key, data) {
      window.sessionStorage.setItem(key, JSON.stringify(data));
    },
    get(key) {
      let data = null;
      try {
        let jsonData = window.sessionStorage.getItem(key);
        data = JSON.parse(jsonData);
      } catch (e) {}
      return data;
    },
    remove(key) {
      if (key) {
        window.sessionStorage.removeItem(key);
      } else {
        window.sessionStorage.clear()
      }
    }
  }


};